import { highlightElement } from './node_modules/@speed-highlight/core/dist/index.js';

import './node_modules/@speed-highlight/core/dist/languages/css.js';
import './node_modules/@speed-highlight/core/dist/languages/diff.js';
import './node_modules/@speed-highlight/core/dist/languages/html.js';
import './node_modules/@speed-highlight/core/dist/languages/ini.js';
import './node_modules/@speed-highlight/core/dist/languages/js.js';
import './node_modules/@speed-highlight/core/dist/languages/make.js';
import './node_modules/@speed-highlight/core/dist/languages/md.js';
import './node_modules/@speed-highlight/core/dist/languages/plain.js';
import './node_modules/@speed-highlight/core/dist/languages/py.js';
import './node_modules/@speed-highlight/core/dist/languages/ts.js';
import './node_modules/@speed-highlight/core/dist/languages/xml.js';
import './node_modules/@speed-highlight/core/dist/languages/yaml.js';
import './node_modules/@speed-highlight/core/dist/languages/rs.js';

import PhotoSwipeLightbox from './node_modules/photoswipe/dist/photoswipe-lightbox.esm.min.js';
import PhotoSwipe from './node_modules/photoswipe/dist/photoswipe.esm.min.js';

import { parseHTML } from './helper.js';

const FSDE_GRID_GUTTER_HALF: number = 15;      // pixel
const FSDE_TRANSITION_DURATION: number = 500;  // ms
const FSDE_COLOR_BLACK: string = '#30363C';
const FSDE_COLOR_WHITE: string = '#FAFAFA';


// theme ----------------------------------------------------------------------
function setTheme() {
    const html: HTMLElement = document.querySelector('html');

    let themeName: string;
    let themeColor: string;

    if(html.classList.contains('fsde-theme-light')) {
        themeName = 'light';
        themeColor = FSDE_COLOR_WHITE;
    } else if(html.classList.contains('fsde-theme-dark')) {
        themeName = 'dark';
        themeColor = FSDE_COLOR_BLACK;
    }

    // set meta tag
    const meta = document.querySelector('meta[name="theme-color"]')

    meta.setAttribute('content', themeColor);

    // write theme setting into cookie
    document.cookie = `fsde-theme=${themeName}; path=/`;
}

function setupThemeToggle() {
    document.querySelectorAll('.fsde-theme-toggle').forEach(element => {
        element.addEventListener('click', () => {
            const html: HTMLElement = document.querySelector('html');

            html.classList.toggle('fsde-theme-light');
            html.classList.toggle('fsde-theme-dark');

            setTheme();
        });
    });
};

// mobile navigation ----------------------------------------------------------
function showMobileNavigation(element: HTMLElement) {
    const height = `${element.scrollHeight + FSDE_GRID_GUTTER_HALF}px`;

    element.style.transition = 'height 300ms';
    element.style.overflow = 'hidden';
    element.style.height = height;

    setTimeout(() => {
        element.style.transition = '';
        element.style.overflow = '';
        element.style.height = height;
    }, 500);
};

function hideMobileNavigation(element: HTMLElement) {
    element.style.transition = 'height 300ms';
    element.style.overflow = 'hidden';
    element.style.height = `${element.scrollHeight}px`;

    setTimeout(() => {
        element.style.height = '0px';
    }, 0);
};

function hideMobileNavigationIfOpen() {
    const body = document.querySelector('body');
    const mobileMenu = document.querySelector('.fsde-mobile-menu');

    if(body.classList.contains('fsde-mobile-menu-open')) {
        body.classList.remove('fsde-mobile-menu-open');
        hideMobileNavigation(<HTMLElement>mobileMenu);
    }

    const toggles = document.querySelectorAll(
        '.fsde-mobile-navigation-toggle',
    );

    toggles.forEach(element => {
        element.classList.remove('fa-xmark');
        element.classList.add('fa-bars');
    });
};

function setupMobileNavigation() {
    const body = document.querySelector('body');

    const toggles = document.querySelectorAll(
        '.fsde-mobile-navigation-toggle',
    );

    const mobileMenu = document.querySelector('.fsde-mobile-menu');

    toggles.forEach(element => {
        element.addEventListener('click', () => {
            body.classList.toggle('fsde-mobile-menu-open');

            if(body.classList.contains('fsde-mobile-menu-open')) {
                showMobileNavigation(<HTMLElement>mobileMenu);
            } else {
                hideMobileNavigation(<HTMLElement>mobileMenu);
            };

            element.classList.toggle('fa-bars');
            element.classList.toggle('fa-xmark');
        });
    });
};

// code highlighting ----------------------------------------------------------
function highlightCode() {
    const codeElements = document.querySelectorAll(
        'main.fsde-main code.shj-inline',
    );

    codeElements.forEach(codeElement => {
        const text = codeElement.textContent;

        // highlight element
        highlightElement(codeElement);

        // copy to clipboard
        if(!window.isSecureContext) {
            console.debug('copy to clipboard is not available because the current context is unsecure');

            return;
        };

        const trigger = parseHTML(`<div class="copy-to-clipboard" href="#" title="Copy to clipboard"></div>`);

        trigger.addEventListener('click', () => {
            navigator.clipboard.writeText(text);

            trigger.innerHTML = 'Copied';

            setTimeout(() => {
                trigger.innerHTML = '';
            }, 3000);
        });

        setTimeout(() => {
            // This timeout is necessary to ensure that the copy to clipboard
            // trigger gets appended after speed-highlight is done highlighting
            // the code element.

            codeElement.prepend(trigger);
        }, 0);
    });
};

// photoswipe -----------------------------------------------------------------
function setupPhotoSwipe() {
    const lightbox = new PhotoSwipeLightbox({
        gallery: '#gallery',
        children: 'a',
        pswpModule: PhotoSwipe
    });

    lightbox.init();
};

// swup -----------------------------------------------------------------------
function setupSwup() {
    // ts ignore is necessary because swup.js uses require.js for its imports

    const htmlElement = document.querySelector('html');

    // @ts-ignore
    window['swup'] = new Swup({
        containers: ['.swup'],
        linkSelector: 'a:not(.external)',

        // @ts-ignore
        plugins: [new SwupGaPlugin({
            gaMeasurementId: 'G-D25LQWYJ0S',
        })],
    });

    // @ts-ignore
    window['swup'].on('contentReplaced', () => {
        hideMobileNavigationIfOpen();
        highlightCode();
        setupPhotoSwipe();
        htmlElement.scrollTo(0, 0);
    });
};

// setup ----------------------------------------------------------------------
window.addEventListener('load', () => {
    setTheme();
    highlightCode();
    setupPhotoSwipe();
    setupThemeToggle();
    setupMobileNavigation();
    setupSwup();

    // setup CSS transitions
    document.body.style['transition-duration'] = `${FSDE_TRANSITION_DURATION}ms`;
});
